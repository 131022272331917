import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "container"
    }}>{`Container`}</h1>
    <p>{`The Container are based on the Bootstrap 5 grid, see docs `}<a parentName="p" {...{
        "href": "https://getbootstrap.com/docs/5.0/"
      }}>{`here`}</a></p>
    <h2 {...{
      "id": "how-they-work"
    }}>{`How they work`}</h2>
    <p>{`Containers are the most basic layout element and are `}<strong parentName="p">{`required when using our default grid system`}</strong>{`. Containers are used to contain, pad, and (sometimes) center the content within them. While containers `}<em parentName="p">{`can`}</em>{` be nested, most layouts do not require a nested container.`}</p>
    <p>{`CSS comes with two different containers:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`.container`}</inlineCode>{`, which sets a `}<inlineCode parentName="li">{`max-width`}</inlineCode>{` at each responsive breakpoint`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`.container-fluid`}</inlineCode>{`, which is `}<inlineCode parentName="li">{`width: 100%`}</inlineCode>{` at all breakpoints`}</li>
    </ul>
    <p>{`The table below illustrates how each container's `}<inlineCode parentName="p">{`max-width`}</inlineCode>{` across each breakpoint.`}</p>
    <table style={{
      "maxWidth": "700px"
    }}>
  <thead>
    <tr>
      <th className="border-dark"></th>
      <th scope="col">
        Extra small<br />
        <span className="text-bold">&lt;576px</span>
      </th>
      <th scope="col">
        Small<br />
        <span className="text-bold">&ge;576px</span>
      </th>
      <th scope="col">
        Medium<br />
        <span className="text-bold">&ge;768px</span>
      </th>
      <th scope="col">
        Large<br />
        <span className="text-bold">&ge;992px</span>
      </th>
      <th scope="col">
        X-Large<br />
        <span className="text-bold">&ge;1200px</span>
      </th>
      <th scope="col">
        XX-Large<br />
        <span className="text-bold">&ge;1400px</span>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td scope="row" className="text-bold">.container</td>
      <td className="gray">100%</td>
      <td>540px</td>
      <td>720px</td>
      <td>960px</td>
      <td>1140px</td>
      <td>1320px</td>
    </tr>
    <tr>
      <td scope="row" className="text-bold">.container-fluid</td>
      <td className="gray">100%</td>
      <td className="gray">100%</td>
      <td className="gray">100%</td>
      <td className="gray">100%</td>
      <td className="gray">100%</td>
      <td className="gray">100%</td>
    </tr>
  </tbody>
    </table>
    <h2 {...{
      "id": "default-container"
    }}>{`Default container`}</h2>
    <p>{`Our default `}<inlineCode parentName="p">{`.container`}</inlineCode>{` class is a responsive, fixed-width container, meaning its `}<inlineCode parentName="p">{`max-width`}</inlineCode>{` changes at each breakpoint.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="container">
  <!-- Content here -->
</div>
`}</code></pre>
    <h2 {...{
      "id": "fluid-containers"
    }}>{`Fluid containers`}</h2>
    <p>{`Use `}<inlineCode parentName="p">{`.container-fluid`}</inlineCode>{` for a full width container, spanning the entire width of the viewport.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="container-fluid">
  ...
</div>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      